@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import 'booksprout-app/src/css/functions/rem-fn'

.four-o-four
  .bs-page
    padding-block: rem-fn(80)

  .ellipse
    block-size: rem-fn(338)
    margin-block-end: rem-fn(40)

    div
      border: rem-fn(1) solid
      box-sizing: border-box
      border-radius: 50%
      position: relative

    .one
      block-size: rem-fn(338)
      border-color: rgba($bs-light-blue-really, .4)
      inline-size: rem-fn(338)
      position: relative

    .two
      border-color: rgba($bs-light-blue-really, .6)
      block-size: rem-fn(281)
      inline-size: rem-fn(281)
      left: calc(50% - #{rem-fn(140)})
      position: relative
      top: calc(50% - #{rem-fn(140)})

    .three
      border-color: rgba($bs-light-blue-really, .8)
      block-size: rem-fn(220)
      inline-size: rem-fn(220)
      left: calc(50% - #{rem-fn(110)})
      position: relative
      top: calc(50% - #{rem-fn(110)})

    .four
      block-size: rem-fn(142)
      border-color: $bs-light-blue-really
      inline-size: rem-fn(142)
      left: calc(50% - #{rem-fn(71)})
      position: relative
      top: calc(50% - #{rem-fn(71)})

    .center
      background-color: #fff
      border-radius: 50%
      block-size: rem-fn(78)
      box-shadow: 0 rem-fn(1) rem-fn(2) $bs-drop-shadow
      inline-size: rem-fn(78)

  h2
    margin-block-end: rem-fn(30)

.f-o-f-actions
  display: grid
  justify-content: center
  gap: rem-fn(20)

  .bs-btn
    border-color: currentColor
    inline-size: rem-fn(200)
    margin-inline: auto
    text-transform: uppercase

    &:nth-child(3)
      grid-column: span 2
